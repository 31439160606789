<template>
	<v-row class="fill-height" align-content="center" justify="center">
		<v-icon large color="deep-purple lighten-5" class="icon-gin">
			mdi-{{iconEmpty}}
		</v-icon>
        <span>{{this.text}}</span>
	</v-row>
</template>

<script>
export default {
	name: "Empty",
	props: ['icon', 'msg'],
    data: () => ({
        iconEmpty: '',
        text: '',
    }),
    mounted() {
        if (this.icon) {
            this.iconEmpty = this.icon;
        } else {
            this.iconEmpty = 'archive-outline';
        }

        if (this.msg) {
            this.text = this.msg;
        } else {
            this.text = this.$t("global.components.empty.txtEmptyData");
        }
    }
};
</script>

<style scoped>
.fill-height {
	width: 100%;
	min-height: 70vh;
    margin: 0 !important;
}

.fill-height span {
    color: #CFD8DC;
}

.icon-gin {
    font-size: 10em !important;
    width: 100%;
}
</style>
